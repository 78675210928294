export const PageSize = 10;
export const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
export const fileExtension = ".xlsx";
export const productMenuLists = [
  [
    "InCExit Product Dashboard",
    "InCExit Onboarded Clients",
    "InCExit Interview Manage",
    "InCExit Interview Search",
    "InCExit All Requirements",
    "InCExit All Interviews",
  ],
  [
    "InCServe Product Dashboard",
    "InCServe Onboarded Clients",
    "InCServe Interviewer Lists",
    "Interviewer Pending",
    "Interviewer Approval",
    "Interviewer Active",
    "Interviewer Blacklisted",
    "Interviewer Deactivated",
    "Interviewer Deleted",
    "InCServe Interview Manage",
    "InCServe Interview Search",
    "InCServe Positions",
    "InCServe All Requirements",
    "InCServe All Interviews",
  ],
  [
    "InCFeed Product Dashboard",
    "InCFeed Onboarded Clients",
    "InCFeed Panelist Lists",
    "InCFeed Interview Manage",
    "InCFeed Positions",
    "InCFeed All Interviews",
  ],
  [
    "InCVid Product Dashboard",
    "InCVid Onboarded Clients",
    "InCVid Panelist Lists",
    "InCVid Interview Manage",
    "InCVid Positions",
    "InCVid All Interviews",
  ],
];
export const filterResources = [
  "Dashboard",
  "Employer Email Verification",
  "Employer Profile Management",
  "Employer Suspended",
  "Employer Deleted",
  "Subscribed List",
  "Employer Subusers",
  "Products",
  "Custom Plans",
  "Add Ons",
  "Admin Roles",
  "Admin Subusers",
  "Positions Manage",
  "Contact Inquiries",
  "Support",
  "Announcements",
  "Careers",
  "Refer N Earn",
  "Question Banks",
  "Excel Download",
];

export const productPermissionDropDown = {
  productPermission: [
    { label: "Jobs", value: "jobs" },
    { label: "InCServe", value: "inCServe" },
    { label: "Exit Interview", value: "exitInterview" },
  ],
  count: [
    { label: 0, value: 0 },
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
  ],
  creditType: [
    { label: "Prepaid", value: "prepaid" },
    { label: "Pay Per Use", value: "payPerUse" },
  ],
  integerations: [
    { label: "Enabled", value: true },
    { label: "Disabled", value: false },
  ],
  creditSystem: [
    { label: "No Show System", value: "noShowSystem" },
    {
      label: "Reschedule / Cancellation System",
      // value: "resheduleOrCancellationSystem",
      value: "reschedule",
    },
  ],
  candidateVerification: [
    { label: "Required", value: true },
    { label: "Not Required", value: false },
  ],
  subclientName: [
    { label: "Allowed", value: true },
    { label: "Not Allowed", value: false },
  ],
  recording: [
    { label: "Show", value: true },
    { label: "Hide", value: false },
  ],
  whiteLabel: [
    { label: "Disabled", value: "disabled" },
    { label: "Partial", value: "partial" },
    { label: "Full", value: "full" },
  ],
  interviewSystem: [
    {
      label: "Shortlisted / Rejected System",
      value: "shortlisted",
    },
    { label: "Rating System", value: "ratingSystem" },
  ],
  ratingSystemType: [
    { label: "Excellent, Good, Average", value: "old" },
    {
      label: "Exceptional Performer, Outstanding Performer, Competent Perfomer",
      value: "new",
    },
  ],
  reportType: [
    { label: "Online Report / PDF", value: "onlineReport/PDF" },
    { label: "Excel", value: "excel" },
  ],
  completedCsvType: [
    { label: "Regular", value: "regular" },
    { label: "Detailed Skills", value: "detailedSkills" },
  ],
};

export const timeSlots = [
  "00:00",
  "00:15",
  "00:30",
  "00:45",
  "01:00",
  "01:15",
  "01:30",
  "01:45",
  "02:00",
  "02:15",
  "02:30",
  "02:45",
  "03:00",
  "03:15",
  "03:30",
  "03:45",
  "04:00",
  "04:15",
  "04:30",
  "04:45",
  "05:00",
  "05:15",
  "05:30",
  "05:45",
  "06:00",
  "06:15",
  "06:30",
  "06:45",
  "07:00",
  "07:15",
  "07:30",
  "07:45",
  "08:00",
  "08:15",
  "08:30",
  "08:45",
  "09:00",
  "09:15",
  "09:30",
  "09:45",
  "10:00",
  "10:15",
  "10:30",
  "10:45",
  "11:00",
  "11:15",
  "11:30",
  "11:45",
  "12:00",
  "12:15",
  "12:30",
  "12:45",
  "13:00",
  "13:15",
  "13:30",
  "13:45",
  "14:00",
  "14:15",
  "14:30",
  "14:45",
  "15:00",
  "15:15",
  "15:30",
  "15:45",
  "16:00",
  "16:15",
  "16:30",
  "16:45",
  "17:00",
  "17:15",
  "17:30",
  "17:45",
  "18:00",
  "18:15",
  "18:30",
  "18:45",
  "19:00",
  "19:15",
  "19:30",
  "19:45",
  "20:00",
  "20:15",
  "20:30",
  "20:45",
  "21:00",
  "21:15",
  "21:30",
  "21:45",
  "22:00",
  "22:15",
  "22:30",
  "22:45",
  "23:00",
  "23:15",
  "23:30",
  "23:45",
];

export const timeDurations = [
  { value: 30, label: "30 mins" },
  { value: 45, label: "45 mins" },
  { value: 60, label: "60 mins" },
];

export const displayTimeSlots = {
  "00:00": "12:00 AM",
  "00:15": "12:15 AM",
  "00:30": "12:30 AM",
  "00:45": "12:45 AM",
  "01:00": "01:00 AM",
  "01:15": "01:15 AM",
  "01:30": "01:30 AM",
  "01:45": "01:45 AM",
  "02:00": "02:00 AM",
  "02:15": "02:15 AM",
  "02:30": "02:30 AM",
  "02:45": "02:45 AM",
  "03:00": "03:00 AM",
  "03:15": "03:15 AM",
  "03:30": "03:30 AM",
  "03:45": "03:45 AM",
  "04:00": "04:00 AM",
  "04:15": "04:15 AM",
  "04:30": "04:30 AM",
  "04:45": "04:45 AM",
  "05:00": "05:00 AM",
  "05:15": "05:15 AM",
  "05:30": "05:30 AM",
  "05:45": "05:45 AM",
  "06:00": "06:00 AM",
  "06:15": "06:15 AM",
  "06:30": "06:30 AM",
  "06:45": "06:45 AM",
  "07:00": "07:00 AM",
  "07:15": "07:15 AM",
  "07:30": "07:30 AM",
  "07:45": "07:45 AM",
  "08:00": "08:00 AM",
  "08:15": "08:15 AM",
  "08:30": "08:30 AM",
  "08:45": "08:45 AM",
  "09:00": "09:00 AM",
  "09:15": "09:15 AM",
  "09:30": "09:30 AM",
  "09:45": "09:45 AM",
  "10:00": "10:00 AM",
  "10:15": "10:15 AM",
  "10:30": "10:30 AM",
  "10:45": "10:45 AM",
  "11:00": "11:00 AM",
  "11:15": "11:15 AM",
  "11:30": "11:30 AM",
  "11:45": "11:45 AM",
  "12:00": "12:00 PM",
  "12:15": "12:15 PM",
  "12:30": "12:30 PM",
  "12:45": "12:45 PM",
  "13:00": "01:00 PM",
  "13:15": "01:15 PM",
  "13:30": "01:30 PM",
  "13:45": "01:45 PM",
  "14:00": "02:00 PM",
  "14:15": "02:15 PM",
  "14:30": "02:30 PM",
  "14:45": "02:45 PM",
  "15:00": "03:00 PM",
  "15:15": "03:15 PM",
  "15:30": "03:30 PM",
  "15:45": "03:45 PM",
  "16:00": "04:00 PM",
  "16:15": "04:15 PM",
  "16:30": "04:30 PM",
  "16:45": "04:45 PM",
  "17:00": "05:00 PM",
  "17:15": "05:15 PM",
  "17:30": "05:30 PM",
  "17:45": "05:45 PM",
  "18:00": "06:00 PM",
  "18:15": "06:15 PM",
  "18:30": "06:30 PM",
  "18:45": "06:45 PM",
  "19:00": "07:00 PM",
  "19:15": "07:15 PM",
  "19:30": "07:30 PM",
  "19:45": "07:45 PM",
  "20:00": "08:00 PM",
  "20:15": "08:15 PM",
  "20:30": "08:30 PM",
  "20:45": "08:45 PM",
  "21:00": "09:00 PM",
  "21:15": "09:15 PM",
  "21:30": "09:30 PM",
  "21:45": "09:45 PM",
  "22:00": "10:00 PM",
  "22:15": "10:15 PM",
  "22:30": "10:30 PM",
  "22:45": "10:45 PM",
  "23:00": "11:00 PM",
  "23:15": "11:15 PM",
  "23:30": "11:30 PM",
  "23:45": "11:45 PM",
};

export const customTimeSlots = [
  { value: "00:00", label: "12:00 AM" },
  { value: "00:15", label: "12:15 AM" },
  { value: "00:30", label: "12:30 AM" },
  { value: "00:45", label: "12:45 AM" },

  { value: "01:00", label: "01:00 AM" },
  { value: "01:15", label: "01:15 AM" },
  { value: "01:30", label: "01:30 AM" },
  { value: "01:45", label: "01:45 AM" },

  { value: "02:00", label: "02:00 AM" },
  { value: "02:15", label: "02:15 AM" },
  { value: "02:30", label: "02:30 AM" },
  { value: "02:45", label: "02:45 AM" },

  { value: "03:00", label: "03:00 AM" },
  { value: "03:15", label: "03:15 AM" },
  { value: "03:30", label: "03:30 AM" },
  { value: "03:45", label: "03:45 AM" },

  { value: "04:00", label: "04:00 AM" },
  { value: "04:15", label: "04:15 AM" },
  { value: "04:30", label: "04:30 AM" },
  { value: "04:45", label: "04:45 AM" },

  { value: "05:00", label: "05:00 AM" },
  { value: "05:15", label: "05:15 AM" },
  { value: "05:30", label: "05:30 AM" },
  { value: "05:45", label: "05:45 AM" },

  { value: "06:00", label: "06:00 AM" },
  { value: "06:15", label: "06:15 AM" },
  { value: "06:30", label: "06:30 AM" },
  { value: "06:45", label: "06:45 AM" },

  { value: "07:00", label: "07:00 AM" },
  { value: "07:15", label: "07:15 AM" },
  { value: "07:30", label: "07:30 AM" },
  { value: "07:45", label: "07:45 AM" },

  { value: "08:00", label: "08:00 AM" },
  { value: "08:15", label: "08:15 AM" },
  { value: "08:30", label: "08:30 AM" },
  { value: "08:45", label: "08:45 AM" },

  { value: "09:00", label: "09:00 AM" },
  { value: "09:15", label: "09:15 AM" },
  { value: "09:30", label: "09:30 AM" },
  { value: "09:45", label: "09:45 AM" },

  { value: "10:00", label: "10:00 AM" },
  { value: "10:15", label: "10:15 AM" },
  { value: "10:30", label: "10:30 AM" },
  { value: "10:45", label: "10:45 AM" },

  { value: "11:00", label: "11:00 AM" },
  { value: "11:15", label: "11:15 AM" },
  { value: "11:30", label: "11:30 AM" },
  { value: "11:45", label: "11:45 AM" },

  { value: "12:00", label: "12:00 PM" },
  { value: "12:15", label: "12:15 PM" },
  { value: "12:30", label: "12:30 PM" },
  { value: "12:45", label: "12:45 PM" },

  { value: "13:00", label: "01:00 PM" },
  { value: "13:15", label: "01:15 PM" },
  { value: "13:30", label: "01:30 PM" },
  { value: "13:45", label: "01:45 PM" },

  { value: "14:00", label: "02:00 PM" },
  { value: "14:15", label: "02:15 PM" },
  { value: "14:30", label: "02:30 PM" },
  { value: "14:45", label: "02:45 PM" },

  { value: "15:00", label: "03:00 PM" },
  { value: "15:15", label: "03:15 PM" },
  { value: "15:30", label: "03:30 PM" },
  { value: "15:45", label: "03:45 PM" },

  { value: "16:00", label: "04:00 PM" },
  { value: "16:15", label: "04:15 PM" },
  { value: "16:30", label: "04:30 PM" },
  { value: "16:45", label: "04:45 PM" },

  { value: "17:00", label: "05:00 PM" },
  { value: "17:15", label: "05:15 PM" },
  { value: "17:30", label: "05:30 PM" },
  { value: "17:45", label: "05:45 PM" },

  { value: "18:00", label: "06:00 PM" },
  { value: "18:15", label: "06:15 PM" },
  { value: "18:30", label: "06:30 PM" },
  { value: "18:45", label: "06:45 PM" },

  { value: "19:00", label: "07:00 PM" },
  { value: "19:15", label: "07:15 PM" },
  { value: "19:30", label: "07:30 PM" },
  { value: "19:45", label: "07:45 PM" },

  { value: "20:00", label: "08:00 PM" },
  { value: "20:15", label: "08:15 PM" },
  { value: "20:30", label: "08:30 PM" },
  { value: "20:45", label: "08:45 PM" },

  { value: "21:00", label: "09:00 PM" },
  { value: "21:15", label: "09:15 PM" },
  { value: "21:30", label: "09:30 PM" },
  { value: "21:45", label: "09:45 PM" },

  { value: "22:00", label: "10:00 PM" },
  { value: "22:15", label: "10:15 PM" },
  { value: "22:30", label: "10:30 PM" },
  { value: "22:45", label: "10:45 PM" },

  { value: "23:00", label: "11:00 PM" },
  { value: "23:15", label: "11:15 PM" },
  { value: "23:30", label: "11:30 PM" },
  { value: "23:45", label: "11:45 PM" },
];

export const interviewSearchDropdownOptions = {
  mode: [
    { label: "Select mode", value: "" },
    { label: "Tele Conferencing", value: "Tele Conferencing" },
    { label: "Video Conferencing", value: "Video Conferencing" },
  ],
  feedback: [
    { label: "Select feedback", value: "" },
    { label: "Conducted", value: "conducted" },
    { label: "No show", value: "noshow" },
  ],
  platform: [
    { label: "Auto Select", value: "auto_select" },
    { label: "Clan", value: "clan" },
    { label: "100ms", value: "100ms" },
    { label: "Zoom", value: "zoom" },
  ],
  type: [
    { label: "Exit Interview", value: "Exit Interview" },
    { label: "Retention Interview", value: "Retention Interview" },
  ],
  gender: [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Other", value: "Other" },
  ],
  zone: [
    { label: "East", value: "East" },
    { label: "West", value: "West" },
    { label: "North", value: "North" },
    { label: "South", value: "South" },
  ],
  positionLevel: [
    { label: "Entry Level", value: "Entry Level" },
    { label: "Lower Management", value: "Lower Management" },
    { label: "Middle Management", value: "Middle Management" },
    { label: "Top Management", value: "Top Management" },
  ],
  feedbackType: [
    { label: "Feedback System", value: "feedback_system" },
    { label: "CAR System", value: "CAR_system" },
  ],
};

export const incbotInterviewDurationList = [
  {
    label: "15",
    value: 15,
  },
  {
    label: "30",
    value: 30,
  },
  {
    label: "60",
    value: 60,
  },
  {
    label: "90",
    value: 90,
  },
];

export const VALID_BUSINESS_EMAIL_ID =  /^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn|live|ymail|icloud|yahoomail|aol)\..+)(.+@.+\..+)$/;
export const VALID_INCRUITER_EMAIL_ID =  /^(?!.+@(incruiter)\..+)(.+@.+\..+)$/;

export const MCQ_TYPE_TEXT = {
  logical_reasoning: 'Logical Reasoning',
  quantitative: 'Quantitative',
  number_system: 'Numerical Reasoning',
  verbal_reasoning: 'Verbal Reasoning',
};